import revive_payload_client_Frd8gASYWa from "/home/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_3Bi0E2Ktsf from "/home/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CrWB4n4PyO from "/home/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_T5aD6w6QZh from "/home/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_yxgjJ9XxnV from "/home/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_BKbTeWPbAR from "/home/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/app/agent-snap/.nuxt/components.plugin.mjs";
import prefetch_client_5ZRYOjuei0 from "/home/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_qPFCDYkbBq from "/home/app/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import plugin_A24OwywIxT from "/home/app/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import chunk_reload_client_kCUITUWXUN from "/home/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import Axios_EFN8pnd1p6 from "/home/app/base/plugins/Axios.ts";
import CanvasConfetti_rPfrnZwJzm from "/home/app/base/plugins/CanvasConfetti.ts";
import CheckCustomerStatus_smfIWv8Qri from "/home/app/base/plugins/CheckCustomerStatus.ts";
import Hotjar_TzyfgEgiiJ from "/home/app/base/plugins/Hotjar.ts";
import SendEvent_XBBVnBDNvY from "/home/app/base/plugins/SendEvent.ts";
import SnapApi_QrjIvI6ZpM from "/home/app/base/plugins/SnapApi.ts";
import Toast_j70BNPN74P from "/home/app/base/plugins/Toast.ts";
import VueCrypto_c4aRbnD9GA from "/home/app/base/plugins/VueCrypto.ts";
import ofetch_lzpRd4sco9 from "/home/app/base/plugins/ofetch.ts";
import vue_signature_pad_cRg14Zzo0m from "/home/app/base/plugins/vue-signature-pad.ts";
export default [
  revive_payload_client_Frd8gASYWa,
  unhead_3Bi0E2Ktsf,
  router_CrWB4n4PyO,
  payload_client_T5aD6w6QZh,
  check_outdated_build_client_yxgjJ9XxnV,
  plugin_vue3_BKbTeWPbAR,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5ZRYOjuei0,
  plugin_qPFCDYkbBq,
  plugin_A24OwywIxT,
  chunk_reload_client_kCUITUWXUN,
  Axios_EFN8pnd1p6,
  CanvasConfetti_rPfrnZwJzm,
  CheckCustomerStatus_smfIWv8Qri,
  Hotjar_TzyfgEgiiJ,
  SendEvent_XBBVnBDNvY,
  SnapApi_QrjIvI6ZpM,
  Toast_j70BNPN74P,
  VueCrypto_c4aRbnD9GA,
  ofetch_lzpRd4sco9,
  vue_signature_pad_cRg14Zzo0m
]