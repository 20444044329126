import { DwollaCustomerStatus } from '../common/enums/dwolla-customer-status';
import { PlaidCustomerStatus } from '../common/enums/plaid-cutomer-status';

export default defineNuxtPlugin(() => {
  const isVerifiedByDwolla = (dwolla?: string | null): boolean => {
    return Boolean(dwolla && dwolla == DwollaCustomerStatus.Verified);
  };

  const isVerifiedByPlaid = (plaid?: string | null): boolean => {
    return Boolean(plaid && plaid == PlaidCustomerStatus.Verified);
  };

  return {
    provide: {
      isSendPaymentMenuVisible: (type?: string | null): boolean => {
        return !(type && type == 'receive-only');
      },
      isPlaidButtonVisible: (plaid?: string | null): boolean => {
        return Boolean(plaid && plaid == 'unverified');
      },
      isVerifiedByDwolla,
      isVerifiedByPlaid,
      isVerifiedByTypeAndDwolla: (type?: string | null, dwolla?: string | null): boolean => {
        if ((type === UserTypes.Business || type === UserTypes.Personal) && !isVerifiedByDwolla(dwolla)) {
          return false;
        }

        return true;
      },
      validateAmountForSpecialChar: (amount: string): boolean => {
        const pattern = /^\d+(\.\d{1,2})?$/;
        return pattern.test(amount);
      },
      validateEmailForSpecialChar: (email: string): boolean => {
        const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
        return pattern.test(email);
      },
      isCustomerCanAddBank: (canAddBank?: boolean): boolean => {
        return canAddBank ?? false;
      },
      isCustomerApproved: (approved?: boolean): boolean => {
        return approved ?? false;
      },
      isVerifiedByDwollaOrPlaid: (dwolla?: string | null, plaid?: string | null): boolean => {
        return isVerifiedByDwolla(dwolla) || isVerifiedByPlaid(plaid);
      },
      isRegistered: (dwolla?: string | null, plaid?: string | null): boolean => {
        return dwolla !== null || plaid !== null;
      },
    },
  };
});
