import axios, { type AxiosError } from 'axios';

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  const axiosInstance = axios.create({
    baseURL: config.public.BASE_URL,
  });
  const nuxtApp = useNuxtApp();
  const cancelSource = axios.CancelToken.source();

  const skipErrorForEndpoints = ['unseen', 'events'];

  axiosInstance.interceptors.request.use((request) => {
    const token = localStorage.getItem('_snap_token');
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }

    request.headers.set('X-Snapr-Prefix', config.public.APP_PREFIX as string);

    const uid = localStorage.getItem('_profile_uid');
    if (uid) {
      request.headers.set('X-Snapr-Profile-Id', uid);
    }

    return request;
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error: AxiosError<{ message: string | string[] }>) => {
      if (error.response?.status === 401) {
        nuxtApp.$toastError(`You're token has expired. Please login again`);

        localStorage.removeItem('_snap_token');
        localStorage.removeItem('_profile_uid');

        navigateTo('/login');

        return Promise.reject(error);
      }
      const shouldErrorBeSkipped = skipErrorForEndpoints.some((endpoint) =>
        error.request.responseURL.endsWith(endpoint),
      );

      if (shouldErrorBeSkipped) {
        return;
      }

      if (error.response) {
        const message = error.response.data.message;
        if (error.response.status >= 500 || !message) {
          nuxtApp.$toastError(`We're having some trouble`);
        } else {
          nuxtApp.$toastError(typeof message === 'string' ? message : message.join('.\n'));
        }
      }

      return Promise.reject(error);
    },
  );

  return {
    provide: {
      axios: axiosInstance,
      cancelSource,
    },
  };
});

/*

To use axios please setup BASE_URL at nuxt.config.ts like
Then you can get access to this variable like config.public.BASE_URL

runtimeConfig: {
    public: {
      BASE_URL: process.env.BASE_URL,
    },
  },

*/
