import Hotjar from 'vue-hotjar';

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  nuxtApp.vueApp.use(Hotjar, {
    id: config.public.HOTJAR_ID,
    isProduction: config.public.ENVIRONMENT === 'prod',
    snippetVersion: 6,
  });
});
