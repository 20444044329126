export default defineNuxtPlugin(() => {
  return {
    provide: {
      toastError: (messageData: string) => {
        window.$message.error(messageData, {
          closable: true,
          showIcon: false,
        });
      },
      toastSuccess: (messageData: string) => {
        window.$message.success(messageData, {
          closable: true,
          showIcon: false,
        });
      },
      toastInfo: (messageData: string) => {
        window.$message.info(messageData, {
          closable: true,
          showIcon: false,
        });
      },
    },
  };
});
