import { AccountAppType } from '../common/enums/account-app-type';
import EventTypes from '../utils/EventTypes';

export default defineNuxtPlugin(() => {
  const nuxtApp = useNuxtApp();
  const { APP_PREFIX } = useRuntimeConfig().public;
  const route = useRoute();

  return {
    provide: {
      sendEvent: async (data: unknown, type: EventTypes) => {
        try {
          const isAuthorized = !!localStorage.getItem('_snap_token');
          const appType = APP_PREFIX === 'claim' ? AccountAppType.ClaimSnap : AccountAppType.AgentSnap;

          if (isAuthorized) {
            const eventBody = {
              type,
              data: JSON.stringify(data),
              appType,
            };
            await nuxtApp.$snapApi({ method: 'POST', url: 'events', data: eventBody });
          }
        } catch (error) {
          console.log(error);
        }
      },
      sendButtonClickEvent: async (buttonName: string) => {
        try {
          const path = route.path;
          const data = {
            buttonName,
            path,
          };

          await nuxtApp.$sendEvent(data, EventTypes.AppButtonClicked);
        } catch (error) {
          console.log(error);
        }
      },
    },
  };
});
