import type { Method, AxiosProgressEvent, CancelToken } from 'axios';
import type { ResponseType } from 'axios';

interface HeaderKeyValuePair {
  [key: string]: string;
}

export interface SnapApiProps {
  method: Method;
  url: string;
  params?: URLSearchParams;
  data?: unknown;
  headers?: HeaderKeyValuePair;
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
  cancelToken?: CancelToken;
  responseType?: ResponseType;
}

export default defineNuxtPlugin(() => {
  return {
    provide: {
      snapApi: (snapApiProps: SnapApiProps) => {
        const { method, url, params, data, headers, onUploadProgress, cancelToken, responseType } = snapApiProps;
        const axios = useNuxtApp().$axios;
        return axios(url, { method, params, data, headers, onUploadProgress, cancelToken, responseType });
      },
    },
  };
});

/* EXAMPLE OF USE

<script setup lang="ts">
  const snapApi = useNuxtApp().$snapApi;
  const methods = async () => {
    const asd = await snapApi({
      method: 'GET',
      url: '/action',
      data: {},
      headers: {
        'key': 'value',
      },
      onUploadProgress: (progressEvent) => {},
    });
  }
</script>

*/
