import vueCryptojsMin from 'vue-cryptojs';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(vueCryptojsMin);
});

/* EXAMPLE FOR USE

<script setup lang="ts">
    import CryptoJS from 'crypto-js'

    const cryptojs = inject('cryptojs') as typeof CryptoJS;

    const anyFunction = (password: string) : string => {
        return cryptojs.AES.encrypt(password, 'SALT').toString();
    }
</script>

 */
