import canvasConfetti from 'canvas-confetti';
import type { Options } from 'canvas-confetti';

function fire(particleRatio: number, opts: Options) {
  canvasConfetti(
    Object.assign({}, { y: 0.7, disableForReducedMotion: true }, opts, {
      particleCount: Math.floor(200 * particleRatio),
    }),
  );
}

export default defineNuxtPlugin(() => {
  return {
    provide: {
      fireConfetti: () => {
        fire(0.25, {
          spread: 26,
          startVelocity: 55,
        });
        fire(0.2, {
          spread: 60,
        });
        fire(0.35, {
          spread: 100,
          decay: 0.91,
          scalar: 0.8,
        });
        fire(0.1, {
          spread: 120,
          startVelocity: 25,
          decay: 0.92,
          scalar: 1.2,
        });
        fire(0.1, {
          spread: 120,
          startVelocity: 45,
        });
      },
    },
  };
});
